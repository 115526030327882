<template>
  <div class="order">
    <el-row :gutter="10" style="width: 100%">
      <el-col :lg="12" :md="24" :sm="24" :xl="8" :xs="12">
        <vab-card class="order-card1" shadow="hover">
          <template #header>
            <vab-icon icon="exchange-box-line" />
            名片互动
          </template>
          <el-row class="order-card1-content">
            <template v-for="(item, index) in accountData" :key="index">
              <el-col :span="item.colCount">
                <p>{{ item.name }}</p>
                <h1>
                  <vab-count
                    :decimals="item.countConfig.decimals"
                    :duration="item.countConfig.duration"
                    :end-val="item.countConfig.endVal"
                    :prefix="item.countConfig.prefix"
                    :separator="item.countConfig.separator"
                    :start-val="item.countConfig.startVal"
                    :suffix="item.countConfig.suffix"
                  />
                </h1>
              </el-col>
            </template>
          </el-row>
        </vab-card>
      </el-col>
      <el-col :lg="12" :md="24" :sm="24" :xl="8" :xs="12">
        <vab-card class="order-card2" shadow="hover">
          <template #header>
            <vab-icon icon="exchange-box-line" />
            文件互动
          </template>
          <el-row class="order-card2-content">
            <template v-for="(item, index) in fileData" :key="index">
              <el-col :span="item.colCount">
                <p>{{ item.name }}</p>
                <h1>
                  <vab-count
                    :decimals="item.countConfig.decimals"
                    :duration="item.countConfig.duration"
                    :end-val="item.countConfig.endVal"
                    :prefix="item.countConfig.prefix"
                    :separator="item.countConfig.separator"
                    :start-val="item.countConfig.startVal"
                    :suffix="item.countConfig.suffix"
                  />
                </h1>
              </el-col>
            </template>
          </el-row>
        </vab-card>
      </el-col>
      <el-col :lg="12" :md="24" :sm="24" :xl="8" :xs="12">
        <vab-card class="order-card3" shadow="hover">
          <template #header>
            <vab-icon icon="exchange-box-line" />
            其他互动
          </template>
          <el-row class="order-card3-content">
            <template v-for="(item, index) in otherData" :key="index">
              <el-col :span="item.colCount">
                <p>{{ item.name }}</p>
                <h1>
                  <vab-count
                    :decimals="item.countConfig.decimals"
                    :duration="item.countConfig.duration"
                    :end-val="item.countConfig.endVal"
                    :prefix="item.countConfig.prefix"
                    :separator="item.countConfig.separator"
                    :start-val="item.countConfig.startVal"
                    :suffix="item.countConfig.suffix"
                  />
                </h1>
              </el-col>
            </template>
          </el-row>
        </vab-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { defineComponent, reactive, toRefs, watch } from 'vue'
  import _ from 'lodash'
  import VabCount from '@/extra/VabCount'

  export default defineComponent({
    components: { VabCount },
    props: {
      interactionData: {
        type: Object,
        default: {},
      },
    },
    emits: ['confirm'],
    setup(props, { emit }) {
      const state = reactive({
        // 名片互动数据
        accountData: [
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '累计交换名片数量',
            colCount: 12,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '日交换名片数量',
            colCount: 12,
          },
        ],
        // 文件互动数据
        fileData: [
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '份',
              separator: ',',
              duration: 3000,
            },
            name: '累计上传文件数量',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '次',
              separator: ',',
              duration: 3000,
            },
            name: '查看文件次数',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '次',
              separator: ',',
              duration: 3000,
            },
            name: '下载文件次数',
            colCount: 8,
          },
        ],
        // 其他互动数据
       
        otherData: [
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '次',
              separator: ',',
              duration: 3000,
            },
            name: '累计提问次数',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '次',
              separator: ',',
              duration: 3000,
            },
            name: '展览分享次数',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '次',
              separator: ',',
              duration: 3000,
            },
            name: '会议分享次数',
            colCount: 8,
          },
        ],
      
      })

      watch(
        () => props.interactionData,
        (newVal) => {
          state.accountData[0].countConfig.endVal =
            newVal.exchangeBusinessCardCount
          state.accountData[1].countConfig.endVal =
            newVal.todayExchangeBusinessCardCount
          state.fileData[0].countConfig.endVal = newVal.fileCount
          state.fileData[1].countConfig.endVal = newVal.fileViewCount
          state.fileData[2].countConfig.endVal = newVal.fileDownloadCount
          state.otherData[0].countConfig.endVal = newVal.questionCount
          state.otherData[1].countConfig.endVal = newVal.shareExhibitionCount
          state.otherData[2].countConfig.endVal = newVal.shareMeetingCount
        }
      )
      return {
        ...toRefs(state),
      }
    },
  })
</script>

<style lang="scss" scoped>
  .order {
    display: flex;
    justify-content: space-between;
    &-card1 {
      width: 100%;
      &-content {
        text-align: center;
      }

      :deep() {
        .el-card {
          &__header,
          &__body {
            color: var(--el-color-white) !important;
            background: linear-gradient(to right, #2cdd7d, #189765);
          }
        }
      }
    }

    &-card2 {
      width: 100%;
      &-content {
        text-align: center;
      }

      :deep() {
        .el-card {
          &__header,
          &__body {
            color: var(--el-color-white) !important;
            background: linear-gradient(to right, #2cdd7d, #189765);
          }
        }
      }
    }
    &-card3 {
      width: 100%;
      &-content {
        text-align: center;
      }

      :deep() {
        .el-card {
          &__header,
          &__body {
            color: var(--el-color-white) !important;
            background: linear-gradient(to right, #2cdd7d, #189765);
          }
        }
      }
    }

    margin-bottom: $base-margin;
  }
</style>
